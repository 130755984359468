.testimonials-section {
  padding: 60px 20px;
  background-color: #f7f7f7;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.testimonials-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}

.testimonial {
  max-width: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 20px;
}

.testimonial h3 {
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 768px) {
  .testimonial {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .testimonials-section h2 {
    font-size: 2rem;
  }

  .testimonial p {
    font-size: 1rem;
  }

  .testimonial h3 {
    font-size: 1.3rem;
  }
}
