.purim-detail {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .purim-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .purim-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    text-transform: capitalize;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .purim-description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .purim-kashrus {
    font-size: 1rem;
    color: #7c2234;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .purim-warning {
    font-size: 1rem;
    color: #e74c3c; /* Red color for warnings */
    margin-bottom: 20px;
    font-style: italic;
  }

  /* GiftPackageDetail.css */

/* Tooltip container */
.add-to-cart-btn[disabled]:hover::after {
  content: attr(title); /* Use the title attribute as the tooltip content */
  position: absolute;   /* Position it absolutely */
  bottom: 100%;         /* Position it above the button */
  left: 50%;            /* Center the tooltip */
  transform: translateX(-50%); /* Adjust to truly center */
  background-color: #333; /* Background color of the tooltip */
  color: #fff;          /* Text color */
  padding: 8px;         /* Padding around the text */
  border-radius: 4px;   /* Rounded corners */
  white-space: nowrap;  /* Prevent text from wrapping */
  font-size: 12px;      /* Font size of the tooltip */
  z-index: 10;          /* Ensure it is above other elements */
  opacity: 0;           /* Initially hidden */
  transition: opacity 0.3s; /* Smooth fade-in transition */
  pointer-events: none; /* Ensure tooltip does not interfere with mouse events */
}

/* Show tooltip on hover */
.add-to-cart-btn[disabled]:hover::after {
  opacity: 1; /* Make it visible on hover */
}

/* Optional: Adjust positioning if button is within a container with overflow */
.purim-detail {
  position: relative; /* Ensure positioning context */
}

/* Button disabled state styling */
.add-to-cart-btn[disabled] {
  cursor: not-allowed; /* Show not-allowed cursor */
  background-color: #ccc; /* Gray out the button */
  color: #666; /* Dim the text color */
}

  
  .purim-price {
    font-size: 1.8rem;
    color: #7c2234;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .honey-flavor-selector {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .honey-flavor-dropdown {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .honey-flavor-dropdown label {
    font-size: 1rem;
    color: #333;
  }
  
  .honey-flavor-dropdown select {
    padding: 8px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 60%;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    color:#333
  }
  
  .honey-flavor-dropdown select:focus {
    outline: none;
    border-color: #7c2234;
  }
  
  .honey-flavor-dropdown select:hover {
    border-color: #7c2234;
  }
  
  .add-to-cart-btn {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #7c2234;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 80%;
  }
  
  .add-to-cart-btn:hover {
    background-color: #b74058;
    transform: translateY(-2px);
  }
  
  .notification {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #28a745; /* Green text color */
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    cursor: default;
    width: 75%;
    text-align: center;
    opacity: 1;
    transform: translateY(0);
  }
  
  .notification.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .notification.hide {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .notification .checkmark {
    display: inline-block;
    margin-right: 8px;
    color: #28a745;
    font-size: 1.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .purim-detail {
      padding: 15px;
    }
  
    .purim-title {
      font-size: 2rem;
    }
  
    .purim-description {
      font-size: 1.1rem;
    }
  
    .purim-price {
      font-size: 1.6rem;
      margin-bottom: 25px;
    }
  
    .honey-flavor-dropdown {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .honey-flavor-dropdown select {
      width: 100%;
      margin-top: 10px;
    }
  
    .add-to-cart-btn {
      padding: 12px 25px;
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 576px) {
    .purim-detail {
      padding: 10px;
    }
  
    .purim-title {
      font-size: 1.8rem;
    }
  
    .purim-description {
      font-size: 1rem;
    }
  
    .purim-price {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
  
    .add-to-cart-btn {
      padding: 10px 20px;
      font-size: 1rem;
    }
  }
  
.trending-popup {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 100000;
}

.trending-popup.left {
  left: 20px;
  animation: slideInLeft 0.5s ease-out;
}

.trending-popup.right {
  right: 20px;
  animation: slideInRight 0.5s ease-out;
}

@keyframes slideInLeft {
  from {
    transform: translate(-100%, -50%);
    opacity: 0;
  }
  to {
    transform: translate(0, -50%);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translate(100%, -50%);
    opacity: 0;
  }
  to {
    transform: translate(0, -50%);
    opacity: 1;
  }
}

.trending-content {
  text-align: center;
}

.trending-content h4 {
  color: #7c2234;
  margin: 0 0 10px 0;
  font-size: 1.1rem;
}

.trending-content h5 {
  margin: 10px 0;
  font-size: 1rem;
}

.trending-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin: 10px 0;
}

.trending-price {
  font-weight: bold;
  color: #7c2234;
  margin: 5px 0;
}

.trending-quantity {
  width: 80%;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.trending-add-btn {
  background: #7c2234;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 80%;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.trending-add-btn:hover {
  background: #b74058;
}

@media (max-width: 768px) {
  .trending-popup {
    width: 80px;
    padding: 5px;
  }

  .trending-popup.left {
    left: 5px;
    top: 70%;
  }

  /* Hide the right popup (soldier families) on mobile */
  .trending-popup.right {
    display: none;
  }
  
  .trending-image {
    height: 45px;
    margin: 3px 0;
  }
  
  .trending-content h4 {
    font-size: 0.65rem;
    margin-bottom: 2px;
  }
  
  .trending-content h5 {
    font-size: 0.6rem;
    margin: 2px 0;
  }

  .trending-price {
    font-size: 0.65rem;
    margin: 2px 0;
  }

  .trending-quantity {
    width: 85%;
    padding: 1px;
    margin: 2px 0;
    font-size: 0.6rem;
  }

  .trending-add-btn {
    padding: 3px 6px;
    font-size: 0.6rem;
    margin-top: 2px;
    width: 85%;
  }

  .close-trending-btn {
    top: -6px;
    right: -6px;
    font-size: 12px;
    width: 14px;
    height: 14px;
    line-height: 14px;
  }
}

@media (max-width: 480px) {
  .trending-popup {
    width: 70px;
    padding: 4px;
  }

  .trending-popup.left {
    top: 75%;
  }

  .trending-popup.right {
    top: 25%;  }
  
  .trending-image {
    height: 40px;
  }

  .trending-content h4 {
    font-size: 0.6rem;
  }

  .trending-content h5 {
    font-size: 0.55rem;
  }

  .trending-price {
    font-size: 0.6rem;
  }

  .trending-add-btn {
    font-size: 0.55rem;
    padding: 2px 4px;
  }

  .close-trending-btn {
    top: -5px;
    right: -5px;
    font-size: 10px;
    width: 12px;
    height: 12px;
    line-height: 12px;
  }
}

.close-trending-btn {
  position: absolute;
  top: -12px;
  right: -12px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-trending-btn:hover {
  color: #333;
}

.trending-content {
  position: relative;
}

.hechsher-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hechsher-modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
}

.hechsher-list {
  margin-top: 1rem;
}

.hechsher-item {
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-name {
  font-weight: 500;
  color: #333;
}

.hechsher-name {
  color: #666;
}

.view-hechsher-btn {
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-hechsher-btn:hover {
  background-color: #e9ecef;
}

.israel-flag {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  height: auto;
}
  