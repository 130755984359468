.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px; /* Default font size */
}

.footer-content p {
  margin: 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer nav ul li {
  display: inline-block;
  margin: 0 10px;
}

.footer nav ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer nav ul li a:hover {
  text-decoration: underline;
}

.holdowsky {
  font-size: 14px; /* Default font size */
  margin-top: 10px;
  color: lightgray;
}

.holdowsky a {
  color: rgb(95, 176, 242);
  font-weight: bold; /* Make it bold */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.2s ease; /* Enhance hover effect */
}

.holdowsky a:hover {
  color: #fff;
  transform: scale(1.1); /* Slightly enlarge the link on hover */
  text-decoration: underline;
}

.link {
  display: flex;
  flex-direction: column;
}

.logo-img {
  width: 100px;
  border-radius: 15px;
  margin-top: 5px;
}

.holdowsky {
  display: flex;
  flex-direction: column;
}
@media (max-width: 576px) {
  .footer-content {
    font-size: 10px;
  }

  .holdowsky {
    font-size: 10px;
  }

  .holdowsky a:hover {
    color: rgb(95, 176, 242) !important;
    text-decoration: none !important;
    font-weight: 100;
  }
}

@media (min-width: 577px) and (max-width: 1023px) {
  .footer-content {
    font-size: 12px;
  }

  .holdowsky {
    font-size: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .footer-content {
    font-size: 14px;
  }

  .holdowsky {
    font-size: 11px;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .footer-content {
    font-size: 16px;
  }

  .holdowsky {
    font-size: 16px;
  }
}

@media (min-width: 2560px) {
  .footer-content {
    font-size: 24px;
  }

  .holdowsky {
    font-size: 20px;
  }
}
