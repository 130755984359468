/* General Styles */
.availability-note {
  font-size: 1rem;
  color: #e74c3c;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.gift-packages {
  padding: 20px;
  background-color: #f5f5f5;
}

.gift-packages-section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #222;
  text-transform: capitalize;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.gift-packages-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.banner {
  background-color: #7c2234;
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

.banner p {
  margin: 0;
  font-size: 1rem;
}

.gift-packages-div {
  flex: 1 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  height: 750px;
}

.gift-packages-div:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.gift-packages-image img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-bottom: 2px solid #e9e9e9;
  transition: transform 0.3s ease;
}

.gift-packages-image img:hover {
  transform: scale(1.08);
}

.gift-packages-info {
  padding: 20px;
  text-align: center;
}

.gift-packages-info h3 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 10px;
}

.gift-packages-info p {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .gift-packages-div {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    height: 570px;
  }

  .gift-packages-section-title {
    font-size: 2.2rem;
  }

  .gift-packages-info h3 {
    font-size: 1.4rem;
  }

  .gift-packages-info p {
    font-size: 1.1rem;
  }

  .gift-packages-image img {
    height: 450px;
  }
}

@media (max-width: 768px) {
  .gift-packages-div {
    flex: 1 1 calc(50% - 20px);
  }

  .gift-packages-section-title {
    font-size: 2rem;
  }

  .gift-packages-info h3 {
    font-size: 1.4rem;
  }

  .gift-packages-info p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .gift-packages-div {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
    height: 510px;
  }

  .gift-packages-section-title {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .gift-packages-info h3 {
    font-size: 1.2rem;
  }

  .gift-packages-info p {
    font-size: 0.9rem;
  }

  .gift-packages-image img {
    height: 400px;
  }

  .gift-packages-images {
    gap: 30px;
  }
}

@media (max-width: 400px) {
  .gift-packages-section-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .gift-packages-info h3 {
    font-size: 1.1rem;
  }

  .gift-packages-info p {
    font-size: 0.8rem;
  }

  .gift-packages-div {
    height: 450px;
  }

  .gift-packages-image img {
    height: 350px;
  }

  .gift-packages-images {
    gap: 30px;
  }
}
