@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@400;700&family=Fira+Code:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

p, span, a, li, div {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

code, pre {
  font-family: 'Fira Code', monospace;
}