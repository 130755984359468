.about-page {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-content {
  margin: 20px auto;
  padding: 30px;
  background-color: rgba(249, 249, 249, 0.95);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
}

.about-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.about-content p {
  font-size: 18px;
  margin-bottom: 15px;
  color: #555;
  text-align: left; /* Align text to the left for better readability */
}

.kashrus {
  border-radius: 10px;
  margin: 20px 0; /* Add spacing around the image */
  max-width: 100%;
}

.cta-btn {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 30px;
  background-color: #7c2234;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-btn:hover {
  background-color: #b74058;
  transform: translateY(-3px);
}

/* Responsive Styles */
@media (max-width: 576px) {
  .about-content {
    padding: 20px;
    max-width: 100%;
    margin: 10px;
  }

  .about-content h2 {
    font-size: 20px;
  }

  .about-content p {
    font-size: 16px;
  }

  .cta-btn {
    font-size: 16px;
    padding: 10px 25px;
  }
}

@media (min-width: 577px) and (max-width: 1023px) {
  .about-content {
    padding: 25px;
    max-width: 90%;
  }

  .about-content h2 {
    font-size: 22px;
  }

  .about-content p {
    font-size: 17px;
  }

  .cta-btn {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .about-content {
    max-width: 600px;
    padding: 30px;
  }

  .about-content h2 {
    font-size: 26px;
  }

  .about-content p {
    font-size: 18px;
  }
}
