.canceled-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5; /* Matching your existing background */
    padding: 20px;
    margin: 0;
  }
  
  .canceled-card {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .canceled-card h2 {
    font-size: 2rem; /* Matching similar heading sizes */
    color: #7c2234; /* Use your theme's primary color */
    margin-bottom: 15px;
    text-transform: capitalize;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .canceled-card p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .canceled-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #7c2234;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    width: 80%;
  }
  
  .canceled-button:hover {
    background-color: #b74058; /* Hover effect matching your theme */
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .canceled-card h2 {
      font-size: 1.8rem;
    }
  
    .canceled-card p {
      font-size: 1rem;
    }
  
    .canceled-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 576px) {
    .canceled-card {
      padding: 15px 20px;
    }
  
    .canceled-card h2 {
      font-size: 1.6rem;
    }
  
    .canceled-card p {
      font-size: 0.9rem;
    }
  
    .canceled-button {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  
  @media (max-width: 400px) {
    .canceled-card h2 {
      font-size: 1.4rem;
    }
  
    .canceled-card p {
      font-size: 0.8rem;
    }
  
    .canceled-button {
      font-size: 0.7rem;
      padding: 5px 10px;
    }
  }
  