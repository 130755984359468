.contact-page {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}

.contact-info {
  margin: 20px auto;
  padding: 30px;
  background-color: rgba(249, 249, 249, 0.95);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
}

.contact-info h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.contact-info .info p {
  font-size: 18px;
  margin-bottom: 15px;
  color: #555;
}

.contact-info .info a {
  color: #7c2234;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info .info a:hover {
  color: #b74058;
}

/* Contact Form Styling */
.contact-form {
  margin-top: 30px;
  text-align: left;
}

.contact-form h3 {
  font-size: 22px;
  color: #7c2234;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.contact-form textarea {
  height: 120px;
  resize: none;
}

.contact-form .submit-btn {
  background-color: #7c2234;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form .submit-btn:hover {
  background-color: #b74058;
}

.form-status {
  margin-top: 20px;
  color: #28a745;
  font-size: 16px;
}

.submit-btn {
  position: relative;
  background-color: #7c2234;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-btn:hover {
  background-color: #b74058;
}

.submit-btn:disabled {
  background-color: #b74058; /* Adjust color when disabled */
  cursor: not-allowed;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* Responsive Styles */
@media (max-width: 576px) {
  .contact-info {
    padding: 20px;
    max-width: 100%;
    margin: 10px;
  }

  .contact-info h2 {
    font-size: 20px;
  }

  .contact-info .info p {
    font-size: 16px;
  }

  .contact-form h3 {
    font-size: 20px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-form .submit-btn {
    font-size: 16px;
  }
}

@media (min-width: 577px) and (max-width: 1023px) {
  .contact-info {
    padding: 25px;
    max-width: 90%;
  }

  .contact-info h2 {
    font-size: 22px;
  }

  .contact-info .info p {
    font-size: 17px;
  }

  .contact-form h3 {
    font-size: 21px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 15px;
  }

  .contact-form .submit-btn {
    font-size: 17px;
  }
}

@media (min-width: 1024px) {
  .contact-info {
    max-width: 600px;
    padding: 30px;
  }

  .contact-info h2 {
    font-size: 26px;
  }

  .contact-info .info p {
    font-size: 18px;
  }
}
